root {
    padding: 0;
    margin: 0;
    
}


.uk { 
    height:100vh;
    width:100vw;
    display:flex;
    justify-content:center;
    align-items:center; 
    flex-direction: column;
  }
  


  