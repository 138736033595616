body {
  padding: 0;
  margin: 0;
  background-color: #191919;
  height: -webkit-fill-available;
}

html {
  height: 100%;
  min-height: -webkit-fill-available;
}


/* h1, h2, h3, body, span {
  color: #FFF05A !important;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.countdown-container span, .countdown-container h1 {
  color: white;
}

.countdown-container {
  text-align: center;
}

.countdown-container .countdown {
  color: white;
}

.countdown {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: space-between;
    font-size: 42px;
}

.seperator {
  margin: 32px;
}

.countdown-value {
  font-size: 60px;
}