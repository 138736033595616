.chat {
    background: black;
}

.receive .message{
    color: white !important;
    background: rgb(38, 37, 40);
    padding: 10px;
    border-radius: 5px;
}


.sent .message{
    color: white !important;
    background: #4d8bd9;
    padding: 10px;
    border-radius: 5px;
}

.message {
    margin: 5px 0px 5px 0px;
    min-height: 40px;
}

.chatInput {
    background: #161616;
    border-radius: 18px;
    border: 1px solid #323232;
}

.chat-messages {
    height: 92vh;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-bottom: 65px
}

.chat-messages::-webkit-scrollbar {
display: none;
}


.chatContainer {
    height: 8vh;
    z-index: 4;
    background: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    bottom: 0%;
}

.mneuIcon {
    color: #939393;
    width: 100%;
}

